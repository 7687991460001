import React from "react";
import { Dialog } from "../components/Dialog";
import { Flex, useToast } from "@chakra-ui/react";
import { Large } from "../components/Type";
import { Header1 } from "../components/Header";
import LoginForm from "../views/LoginForm";
import { SingleFormAppShell } from "../components/SingleFormAppShell";
import { useUserInfo } from "../hooks/useUserInfo";
import { useRouter } from "next/dist/client/router";
import { t } from "@lingui/macro";
import { loadCatalog } from "../i18n";
import { useLingui } from "@lingui/react";

export async function getStaticProps({ params, ...ctx }) {
  const translation = await loadCatalog(ctx.locale);
  return { props: { translation } };
}

const Login = () => {
  const toast = useToast();
  const router = useRouter();
  const { i18n } = useLingui();

  useUserInfo({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { currency = "" } = data;
      const locale = currency === "PHP" ? "en" : "id";
      console.log("locale:", locale);
      router.replace("/admin", "/admin", { locale });
      toast({
        title: t`Kamu berhasil login`,
        description: t`Selamat datang!`,
        isClosable: true,
        status: "success",
      });
    },
  });

  return (
    <SingleFormAppShell title="Login">
      <Dialog marginTop={3}>
        <Flex justifyContent="center" direction="column">
          <Header1 />

          <Large m={3} alignSelf="center">
            Login
          </Large>
          <LoginForm />
        </Flex>
      </Dialog>
    </SingleFormAppShell>
  );
};

export default Login;
