import React from "react";
import { Flex, Box } from "@chakra-ui/react";
import { Footer } from "./Footer";
import Head from "./Head";
import { Announcement } from "./Announcement";

const SingleFormAppShell = ({ title, children }) => {
  return (
    <>
      <Announcement />
      <Head title={title} />
      <Flex
        paddingX={["4", "5", 0]}
        alignItems="center"
        justifyContent="center"
        minH="100vh"
        direction="column"
      >
        <Box w={["100%", "100%", "650px"]}>
          {children}
          <Footer marginTop={4}></Footer>
        </Box>
      </Flex>
    </>
  );
};

export { SingleFormAppShell };
